import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';

import styles from './index.module.css';

const GenericMessage = ({ title, subtitle, description, secondaryDescription, buttonLabel, handleButtonPress }) => (
    <section>
        <Text.H1>{title}</Text.H1>
        {description ? <Text.Body1>{description}</Text.Body1> : null}

        <div className={styles.container}>
            {subtitle ? <Text.H3>{subtitle}</Text.H3> : null}
            {secondaryDescription ? <Text.Body1>{secondaryDescription}</Text.Body1> : null}
        </div>

        {handleButtonPress && buttonLabel ? (
            <Button size={Button.sizes.LARGE} onClick={handleButtonPress}>
                {buttonLabel}
            </Button>
        ) : null}
    </section>
);

GenericMessage.propTypes = {
    title: PropTypes.string.isRequired,
    handleButtonPress: PropTypes.func,
    subtitle: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    secondaryDescription: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    buttonLabel: PropTypes.string,
};

GenericMessage.defaultProps = {
    handleButtonPress: undefined,
    subtitle: undefined,
    description: undefined,
    secondaryDescription: undefined,
    buttonLabel: undefined,
};

export default GenericMessage;
