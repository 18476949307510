export default {
    generic: {
        title: "Sorry - we're in the middle of a planned system upgrade.",
        subtitle: "What's going on?",
        secondaryDescription:
            "We are doing some background work on our systems, to make the website work better for you. Our technology team are working hard to get this done quickly, it shouldn't take too long.Please check again later.",
        buttonLabel: 'Return to homepage',
        url: 'https://www.clearscore.com',
    },
};
