import React from 'react';
import Container from '@clearscore/rainbow.container';

import GenericMessage from './components/generic-message';
import lang from './lib/lang';
import styles from './styles.module.css';

const language = lang.generic;

const Maintenance = () => (
    <div className={styles.shell}>
        <Container
            theme={Container.Theme.WHITE}
            top={Container.Spacing.HUGE}
            bottom={Container.Spacing.HUGE}
            size={Container.Size.HUGE}
        >
            <GenericMessage
                title={language.title}
                description={language.description}
                subtitle={language.subtitle}
                secondaryDescription={language.secondaryDescription}
                buttonLabel={language.buttonLabel}
                handleButtonPress={() => window.location.assign(language.url)}
            />
        </Container>
    </div>
);

export default Maintenance;
